<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12" class="my-2">
        <span
          class="font-weight-medium text-body-1 primary--text text--darken-1"
          >{{ $t("route.supplierPaymentAction") }}</span
        >
        <NextAndPreviousCommon
          :nextId="nextId"
          :prevId="prevId"
          :documentId="supplierReceiptId"
          @idChange="redirectOnNextAndPrevious"
        />
      </v-col>
    </v-row>
    <v-row
      no-gutters
      v-if="supplierReceiptId && !!model.is_locked && model.user_id !== user.id"
    >
      <v-col cols="12" class="my-3">
        <span
          class="font-weight-medium text-body-1 primary--text text--darken-1 error--text"
          >*{{
            $t("common.documentReadOnlyModeNotice", { userId: model.user.name })
          }}</span
        >
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto" outlined>
          <v-card-title class="py-1">
            <h5
              v-if="originModel.id"
              style="max-width: 175px"
              :class="
                supplierReceiptId
                  ? 'px-1 text-truncate font-italic primary--text font-weight-bold'
                  : 'primary--text text--darken-1'
              "
              class="font-weight-regular"
            >
              {{
                supplierReceiptId
                  ? `${originModel.id}.`
                  : $t("route.addSupplierPayment")
              }}
            </h5>
            <v-spacer />
            <v-menu
              bottom
              v-if="supplierReceiptId"
              :close-on-click="true"
              :offset-y="true"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :color="
                    remarksList && remarksList.length > 0
                      ? 'success'
                      : 'primary'
                  "
                  class="ma-2"
                  outlined
                  small
                  @click="remarksDialog = true"
                  :disabled="
                    accessRight.length < 2 && accessRight.includes('show')
                  "
                >
                  {{ $t("remarks.remarks") }}
                </v-btn>
                <v-btn
                  color="primary"
                  class="ma-2"
                  small
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :disabled="
                    !valid || (!!model.is_locked && model.user_id !== user.id)
                  "
                >
                  <v-icon left> mdi-menu-down </v-icon>
                  {{ $t("quotation.options") }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="accessRight.includes('create')"
                  dense
                  class="primary--text"
                  @click="copySupplierReceipt"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text"
                        >mdi-content-copy</v-icon
                      >
                      {{ $t("quotation.copy") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  dense
                  class="primary--text"
                  @click="actDialog = true"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text"> mdi-pulse </v-icon>
                      {{ $t("quotation.activityLog") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  dense
                  class="primary--text"
                  @click="attachedDocumentsDialog = true"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text"
                        >mdi-file-document</v-icon
                      >
                      {{ $t("employee.attachedDocuments") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  dense
                  :disabled="selectedStatus == 2"
                  v-if="accessRight.includes('delete')"
                  class="error--text"
                  @click="
                    (confirmationDialogStatus = true),
                      (delete_item = supplierReceiptId)
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="error--text"
                        >mdi-delete-sweep-outline</v-icon
                      >
                      {{ $t("common.delete") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu
              bottom
              v-if="supplierReceiptId"
              :close-on-click="true"
              :offset-y="true"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  class="ma-2"
                  small
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :disabled="
                    !valid || (!!model.is_locked && model.user_id !== user.id)
                  "
                >
                  <v-icon left> mdi-menu-down </v-icon>
                  {{ $t("calendar.calendarEvents") }}
                </v-btn>
              </template>
              <v-list>
                <!-- <LoginGoogle /> -->
                <CommonCalendar
                  :docId="supplierReceiptId"
                  docType="SupplierPayment"
                  :model="model"
                />
              </v-list>
            </v-menu>
            <HtmlToPdf
              v-if="supplierReceiptId"
              class="me-2 mb-1"
              :disabled="
                valid && !(!!model.is_locked && model.user_id !== user.id)
              "
              :model="originModel"
              type="SupplierPaymentPdf"
            />
            <v-btn
              color="primary"
              small
              :loading="loading"
              :disabled="
                !valid ||
                (isFieldReadable &&
                  !saveOnlyCategoryStatus &&
                  !(
                    isFieldReadable &&
                    supplierReceiptId &&
                    supplierReceiptInvoicesModel &&
                    supplierReceiptInvoicesModel.invoiceData
                  ) &&
                  ((isFieldReadable &&
                    originModel.accounting_update &&
                    !!supplierReceiptId) ||
                    (!!model.is_locked && model.user_id !== user.id) ||
                    (isFieldReadable &&
                      !model.accounting_update &&
                      !!supplierReceiptId))) ||
                (accessRight.length < 2 && accessRight.includes('show'))
              "
              @click="saveSupplierReceipt"
            >
              {{ $t("receipt.save") }}
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="text-h5 pa-2">
            <FormSkeleton
              v-if="
                supplierReceiptId &&
                Object.keys(supplierPaymentById).length <= 0
              "
            />
            <v-form
              v-else
              ref="form"
              v-model="valid"
              lazy-validation
              :disabled="accessRight.length < 2 && accessRight.includes('show')"
            >
              <Alert :alert.sync="alert" :message="message" />
              <v-row>
                <v-col cols="6">
                  <v-card class="mx-auto" flat>
                    <v-card-title class="py-1 primary--text">
                      <v-icon color="primary">mdi-account-group-outline</v-icon>
                      <h6 class="ms-2 font-weight-regular">
                        {{ $t("purchaseOrder.supplierDetails") }}
                      </h6>
                      <v-spacer />
                      <v-icon
                        v-if="supplier_id"
                        @click="openSupplierAdditionalDetailModel"
                        color="primary"
                        class="float-end"
                        >mdi-eye-outline</v-icon
                      >
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row :dense="true">
                        <v-col cols="12">
                          <v-combobox
                            class="asterisk"
                            autocomplete="new-password"
                            dense
                            :readonly="isFieldReadable ? true : false"
                            cache-items
                            v-model="selectedSupplier"
                            :items="supplierList.data"
                            item-text="name_1"
                            item-value="id"
                            @change="onSupplierChange"
                            flat
                            :rules="supplierValid"
                            return-object
                            hide-no-data
                            hide-details="auto"
                            required
                            single-line
                            @focus="
                              documentNextAndPrevious(originModel.supplier_id)
                            "
                            @blur="documentNextAndPreviousBlur()"
                            @dblclick="redirectOnEditSupplierPage"
                            :search-input.sync="supplierSearch"
                            :label="$t('supplier.supplier')"
                          >
                            <template v-slot:append-outer>
                              <v-icon
                                color="primary"
                                dense
                                v-if="!isFieldReadable"
                                @click="supplierDialog = true"
                              >
                                mdi-magnify
                              </v-icon>
                            </template>
                            <template v-slot:selection="data">
                              <span @dblclick="redirectOnEditSupplierPage">{{
                                `${data.item.id}. ${data.item.name_1}`
                              }}</span>
                            </template>
                            <template v-slot:item="data">
                              <div
                                class="font-weight-medium"
                                style="
                                  font-size: 0.8125rem;
                                  display: flex;
                                  align-item: center;
                                "
                              >
                                {{ `${data.item.id}. ${data.item.name_1}` }}
                              </div>
                            </template>
                            <template v-slot:append-item>
                              <div
                                v-show="hasSupplierNextPage"
                                v-intersect="infiniteScroll"
                                ref="load"
                                class="loader text-center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                />
                              </div>
                            </template>
                          </v-combobox>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            class="asterisk"
                            autocomplete="new-password"
                            hide-details="auto"
                            :readonly="isFieldReadable"
                            v-model="model.supplier_name"
                            :label="$t('supplier.supplierName')"
                            :rules="supplierNameValid"
                            required
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            :readonly="isFieldReadable"
                            hide-details="auto"
                            v-model="model.address"
                            :label="$t('receipt.field.address')"
                            :rules="[
                              lessThanValidation(
                                $t('receipt.field.address'),
                                $t('common.less'),
                                250,
                                model.address
                              ),
                            ]"
                            required
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            :readonly="isFieldReadable"
                            hide-details="auto"
                            v-model="model.town"
                            :label="$t('receipt.field.town')"
                            :rules="[
                              lessThanValidation(
                                $t('receipt.field.town'),
                                $t('common.less'),
                                100,
                                model.town
                              ),
                            ]"
                            required
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card class="mx-auto" flat>
                    <v-card-title class="py-1 primary--text">
                      <v-icon color="primary"
                        >mdi-file-delimited-outline</v-icon
                      >
                      <h6 class="ms-2 font-weight-regular">
                        {{ $t("supplierPayment.supplierPaymentDetail") }}
                      </h6>
                      <v-spacer />
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row :dense="true">
                        <v-col cols="4">
                          <v-text-field
                            dense
                            hide-details="auto"
                            v-model="model.supplier_receipt_year"
                            readonly
                            :rules="receiptYearValid"
                            required
                            :label="$t('receipt.field.year')"
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-menu
                            v-if="!isFieldReadable"
                            ref="startDateMenu"
                            v-model="startDateMenu"
                            :close-on-content-click="false"
                            :return-value.sync="model.receipt_date"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                dense
                                hide-details="auto"
                                v-model="formattedReceiptDate"
                                :label="$t('receipt.field.receiptDate')"
                                v-bind="attrs"
                                v-on="on"
                                readonly
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="model.receipt_date"
                              :min="minDate"
                              :max="maxDate"
                              @change="
                                $refs.startDateMenu.save(model.receipt_date)
                              "
                            >
                              <v-btn
                                small
                                class="primary"
                                @click="$refs.startDateMenu.save(todayDate())"
                                >{{ $t("route.today") }}</v-btn
                              >
                            </v-date-picker>
                          </v-menu>
                          <v-text-field
                            v-else
                            dense
                            hide-details="auto"
                            v-model="formattedReceiptDate"
                            :label="$t('receipt.field.receiptDate')"
                            readonly
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-combobox
                            autocomplete="new-password"
                            :readonly="isFieldReadable"
                            v-model="selectedEmployee"
                            :items="employeeList.data"
                            item-text="name"
                            item-value="id"
                            @change="onEmployeeChange"
                            :clearable="!isFieldReadable"
                            flat
                            return-object
                            hide-no-data
                            hide-details="auto"
                            dense
                            required
                            single-line
                            @dblclick="redirectOnEditEmployeePage"
                            :search-input.sync="employeeSearch"
                            :label="$t('receipt.field.agent')"
                          >
                            <template v-slot:append-outer>
                              <v-icon
                                class="mt-1"
                                color="primary"
                                dense
                                v-if="!isFieldReadable"
                                @click="employeeDialog = true"
                              >
                                mdi-magnify
                              </v-icon>
                            </template>
                            <template v-slot:item="data">
                              <div
                                class="font-weight-medium"
                                style="
                                  font-size: 0.8125rem;
                                  display: flex;
                                  align-item: center;
                                "
                              >
                                {{ `${data.item.id}. ${data.item.name}` }}
                              </div>
                            </template>
                            <template v-slot:append-item>
                              <div
                                v-show="hasEmployeeNextPage"
                                v-intersect="infiniteScrollOnEmployee"
                                ref="load"
                                class="loader text-center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                />
                              </div>
                            </template>
                          </v-combobox>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card class="mx-auto" flat>
                    <v-card-title class="py-1 primary--text">
                      <v-row dense no-gutters>
                        <v-col cols="8" class="d-flex">
                          <v-icon color="primary">mdi-cart-plus</v-icon>
                          <h6
                            class="ms-2 align-self-center font-weight-regular"
                          >
                            {{ $t("receipt.listOfPayment") }}
                          </h6>
                        </v-col>
                        <v-col cols="4" class="text-end font-weight-regular">
                          <v-btn
                            :disabled="selectedStatus == 2"
                            v-if="supplierReceiptId"
                            class="me-2"
                            @click="getInvoices('updatedDocuments')"
                            color="primary"
                            outlined
                            small
                          >
                            {{ $t("receipt.updatedDocuments") }}
                          </v-btn>
                          <v-btn
                            @click="getInvoices(null)"
                            color="primary"
                            outlined
                            small
                            :disabled="
                              !valid || isFieldReadable || isSupplierReceiptId
                            "
                          >
                            {{ $t("receipt.getInvoices") }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row :dense="true">
                        <v-col cols="12">
                          <v-simple-table
                            dense
                            class="custom-table elevation-1 item-table"
                            fixed-header
                            height="250"
                          >
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th :style="{ width: '40px' }">
                                    {{ $t("receipt.no") }}
                                  </th>
                                  <th :style="{ width: '120px' }">
                                    {{ $t("receipt.field.paymentDate") }}
                                  </th>
                                  <th :style="{ width: '140px' }">
                                    {{ $t("receipt.paymentType") }}
                                  </th>
                                  <th :style="{ width: '140px' }">
                                    {{ $t("receipt.chequeNumber") }}
                                  </th>
                                  <th :style="{ width: '140px' }">
                                    {{ $t("receipt.bankNo") }}
                                  </th>
                                  <th :style="{ width: '140px' }">
                                    {{ $t("receipt.branch") }}
                                  </th>
                                  <th :style="{ width: '110px' }">
                                    {{ $t("receipt.accountNo") }}
                                  </th>
                                  <th
                                    v-if="
                                      !supplierReceiptId ||
                                      (supplierReceiptId &&
                                        accessRight.includes('financial'))
                                    "
                                    :style="{ width: '110px' }"
                                  >
                                    {{ $t("receipt.amount") }}
                                  </th>
                                  <th :style="{ width: '110px' }">
                                    {{ $t("receipt.field.remarks") }}
                                  </th>
                                  <th
                                    class="text-center"
                                    :style="{ width: '100px' }"
                                  >
                                    {{ $t("receipt.action") }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  style="vertical-align: bottom"
                                  v-for="(item, index) in paymentItems"
                                  :key="index"
                                  :class="
                                    index + 1 == paymentItems.length
                                      ? 'newRow'
                                      : ''
                                  "
                                >
                                  <td>
                                    {{ index + 1 }}
                                  </td>
                                  <td>
                                    <v-menu
                                      v-if="!isFieldReadable"
                                      :ref="`startDateMenu${index}`"
                                      v-model="item.startDateMenu"
                                      :close-on-content-click="false"
                                      :return-value.sync="
                                        item.supplier_payment_date
                                      "
                                      transition="scale-transition"
                                      offset-y
                                      min-width="auto"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-text-field
                                          class="date-input"
                                          hide-details="auto"
                                          dense
                                          v-model="
                                            processedPaymentItems[index]
                                              .formattedPaymentDate
                                          "
                                          :rules="paymentDateValid"
                                          required
                                          v-bind="attrs"
                                          v-on="on"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="item.supplier_payment_date"
                                        @change="
                                          $refs[
                                            `startDateMenu${index}`
                                          ][0].save(item.supplier_payment_date)
                                        "
                                      >
                                        <v-btn
                                          small
                                          class="primary"
                                          @click="
                                            $refs[
                                              `startDateMenu${index}`
                                            ][0].save(todayDate())
                                          "
                                          >{{ $t("route.today") }}</v-btn
                                        >
                                      </v-date-picker>
                                    </v-menu>
                                    <v-text-field
                                      v-else
                                      class="date-input"
                                      dense
                                      hide-details="auto"
                                      v-model="
                                        processedPaymentItems[index]
                                          .formattedPaymentDate
                                      "
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <v-select
                                      :readonly="isFieldReadable"
                                      dense
                                      hide-details="auto"
                                      v-model="item.payment_terms_id"
                                      :items="paymentGroupValue"
                                      item-text="description"
                                      item-value="id"
                                      return-object
                                      :rules="paymentTypeValid"
                                      required
                                      :menu-props="{
                                        bottom: true,
                                        offsetY: true,
                                      }"
                                      @change="paymentTypeChange(item, index)"
                                    >
                                      <template v-slot:append-outer>
                                        <v-icon
                                          color="primary"
                                          dense
                                          v-if="
                                            item.payment_terms_id &&
                                            !isFieldReadable &&
                                            (item.payment_terms_id
                                              .description ===
                                              'Bank transfer' ||
                                              item.payment_terms_id
                                                .description === 'העברה')
                                          "
                                          @click="
                                            item.payment_terms_id
                                              ? item.payment_terms_id
                                                  .description ===
                                                  'Bank transfer' ||
                                                item.payment_terms_id
                                                  .description === 'העברה'
                                                ? openBankDialog(index)
                                                : ''
                                              : ''
                                          "
                                        >
                                          mdi-bank
                                        </v-icon>
                                      </template>
                                    </v-select>
                                  </td>
                                  <td>
                                    <v-text-field
                                      :ref="'chequeRef' + index"
                                      dense
                                      :readonly="isFieldReadable"
                                      hide-details="auto"
                                      v-model="item.cheque_no"
                                      :rules="
                                        paymentTypeArray.includes(
                                          item.payment_terms_id
                                            ? item.payment_terms_id.description
                                            : ''
                                        )
                                          ? chequeNumberValid
                                          : []
                                      "
                                      required
                                    />
                                  </td>
                                  <td>
                                    <v-text-field
                                      :ref="'bankRef' + index"
                                      dense
                                      :readonly="isFieldReadable"
                                      hide-details="auto"
                                      v-model="item.bank"
                                      :rules="
                                        paymentTypeArray.includes(
                                          item.payment_terms_id
                                            ? item.payment_terms_id.description
                                            : ''
                                        )
                                          ? bankNoValid
                                          : []
                                      "
                                      required
                                    />
                                  </td>
                                  <td>
                                    <v-text-field
                                      :ref="'branchRef' + index"
                                      dense
                                      :readonly="isFieldReadable"
                                      hide-details="auto"
                                      v-model="item.branch"
                                      :rules="
                                        paymentTypeArray.includes(
                                          item.payment_terms_id
                                            ? item.payment_terms_id.description
                                            : ''
                                        )
                                          ? branchValid
                                          : []
                                      "
                                      required
                                    />
                                  </td>
                                  <td>
                                    <v-text-field
                                      :ref="'accountNoRef' + index"
                                      dense
                                      :readonly="isFieldReadable"
                                      hide-details="auto"
                                      v-model="item.account_no"
                                      :rules="
                                        paymentTypeArray.includes(
                                          item.payment_terms_id
                                            ? item.payment_terms_id.description
                                            : ''
                                        )
                                          ? accountNoValid
                                          : []
                                      "
                                      required
                                    />
                                  </td>
                                  <td
                                    v-if="
                                      !supplierReceiptId ||
                                      (supplierReceiptId &&
                                        accessRight.includes('financial'))
                                    "
                                  >
                                    <v-text-field
                                      dense
                                      :name="'amount' + index"
                                      :ref="'amount' + index"
                                      :readonly="isFieldReadable"
                                      :reverse="locale === 'he' ? false : true"
                                      hide-details="auto"
                                      v-model="item.amount"
                                      :rules="amountValid"
                                      required
                                      @input="itemPriceCalculation(index)"
                                    />
                                  </td>
                                  <td>
                                    <v-text-field
                                      dense
                                      :readonly="isFieldReadable"
                                      hide-details="auto"
                                      v-model="item.remarks"
                                      :title="item.remarks"
                                      :rules="[
                                        lessThanValidation(
                                          $t('receipt.field.remarks'),
                                          $t('common.less'),
                                          25,
                                          item.remarks
                                        ),
                                      ]"
                                      required
                                    />
                                  </td>
                                  <td class="text-center">
                                    <v-row>
                                      <v-col cols="4" class="text-end">
                                        <v-btn
                                          icon
                                          :disabled="
                                            isFieldReadable ||
                                            (accessRight.length < 2 &&
                                              accessRight.includes('show'))
                                          "
                                          color="primary"
                                          small
                                          @click="addRow"
                                        >
                                          <v-icon color="primary">
                                            mdi-plus
                                          </v-icon>
                                        </v-btn>
                                      </v-col>
                                      <v-col cols="4" class="text-end">
                                        <v-btn
                                          icon
                                          :disabled="
                                            isFieldReadable ||
                                            (accessRight.length < 2 &&
                                              accessRight.includes('show')) ||
                                            !item.payment_terms_id
                                          "
                                          color="primary"
                                          x-small
                                          class="mt-1"
                                          @click="copyData(index)"
                                        >
                                          <v-icon color="primary">
                                            mdi-content-copy
                                          </v-icon>
                                        </v-btn>
                                      </v-col>
                                      <v-col cols="4" class="text-start">
                                        <v-btn
                                          v-if="paymentItems.length !== 1"
                                          icon
                                          :disabled="
                                            isFieldReadable ||
                                            (accessRight.length < 2 &&
                                              accessRight.includes('show'))
                                          "
                                          color="error"
                                          small
                                          @click="deleteRow(index)"
                                        >
                                          <v-icon color="error">
                                            mdi-delete-sweep-outline
                                          </v-icon>
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                      <v-row :dense="true" class="pt-6">
                        <v-col cols="8">
                          <v-row :dense="true">
                            <v-col cols="8">
                              <v-row>
                                <v-col cols="6">
                                  <v-radio-group
                                    hide-no-data
                                    hide-details="auto"
                                    dense
                                    single-line
                                    v-model="selectedStatus"
                                    :disabled="isFieldReadable"
                                    readonly
                                    class="text-caption pt-0 mt-3"
                                  >
                                    <template v-slot:label>
                                      <div>
                                        {{ $t("receipt.field.status") }}
                                      </div>
                                    </template>
                                    <v-radio
                                      v-for="statusItem in statusList"
                                      :key="statusItem.id"
                                      :label="`${statusItem.name}`"
                                      :value="statusItem.id"
                                    ></v-radio>
                                  </v-radio-group>
                                </v-col>
                                <v-col cols="6">
                                  <v-checkbox
                                    :dense="true"
                                    v-model="model.accounting_update"
                                    :readonly="
                                      !supplierReceiptId ||
                                      (originModel.status == 0 &&
                                        !supplierReceiptId) ||
                                      (!!supplierReceiptId &&
                                        originModel.status == 0 &&
                                        originModel.supplier &&
                                        originModel.supplier.book_kiping_no &&
                                        !!originModel.accounting_update) ||
                                      (originModel.supplier &&
                                        originModel.supplier.book_kiping_no &&
                                        originModel.accounting_update == 1)
                                    "
                                    hide-details="auto"
                                    @click="
                                      bookkeepingConfirmationDialog =
                                        !!supplierReceiptId &&
                                        originModel.status == 0 &&
                                        originModel.supplier &&
                                        !originModel.supplier.book_kiping_no &&
                                        !originModel.accounting_update
                                          ? true
                                          : false
                                    "
                                    @click.native.stop="
                                      bookkeepingRecordsDialog =
                                        (originModel.accounting_update &&
                                          originModel.status == 1) ||
                                        originModel.status == 2
                                          ? true
                                          : false
                                    "
                                    :label="$t('receipt.field.accountUpdate')"
                                  />
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="4">
                              <v-textarea
                                :readonly="isFieldReadable"
                                hide-details="auto"
                                dense
                                rows="7"
                                v-model="model.remarks"
                                :label="$t('receipt.field.remarks')"
                                :rules="[
                                  lessThanValidation(
                                    $t('receipt.field.remarks'),
                                    $t('common.less'),
                                    250,
                                    model.remarks
                                  ),
                                ]"
                              />
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-divider class="mx-8" vertical />
                        <v-col
                          cols="3"
                          v-if="
                            !supplierReceiptId ||
                            (supplierReceiptId &&
                              accessRight.includes('financial'))
                          "
                        >
                          <v-row dense>
                            <v-col cols="6" class="font-weight-bold">
                              {{ $t("receipt.total") }}
                            </v-col>
                            <v-col
                              cols="6"
                              class="font-weight-bold"
                              :class="
                                locale === 'he' ? 'text-start' : 'text-end'
                              "
                            >
                              {{ numberWithCommas(model.total) }}
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="6">
                              {{ $t("receipt.field.discount") }}
                            </v-col>
                            <v-col cols="6">
                              <v-text-field
                                dense
                                height="18"
                                class="ma-0 pa-0"
                                hide-details="auto"
                                name="tax"
                                ref="tax"
                                :readonly="isFieldReadable"
                                :reverse="locale === 'he' ? false : true"
                                v-model="model.with_holding_tax"
                                :rules="discountValid"
                                required
                                @input="receiptPriceCalculation"
                              />
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="6" class="font-weight-bold">
                              {{ $t("receipt.field.grandTotal") }}
                            </v-col>
                            <v-col
                              cols="6"
                              class="font-weight-bold"
                              :class="
                                locale === 'he' ? 'text-start' : 'text-end'
                              "
                            >
                              {{ numberWithCommas(model.grand_total) }}
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-overlay :z-index="999" v-if="isProgressCircular">
        <v-icon size="64" class="hourglass-icon" color="primary"
          >mdi-timer-sand-paused</v-icon
        >
      </v-overlay>
      <ActivityLogTableDialog
        :actDialog.sync="actDialog"
        :docId.sync="supplierReceiptId"
        docType="SupplierPayment"
        docMode="sales"
        docItems="SupplierReceiptPayment"
        docEmailSent="SupplierPaymentEmailSent"
      />
      <BookkeepingRecordsDialog
        :bookkeepingRecordsDialog.sync="bookkeepingRecordsDialog"
        type="SupplierReceipt"
        :docId.sync="originModel.id"
        docMode="sales"
      />
      <CustomerDetail
        :model.sync="supplierAdditionalDetail"
        :isFieldReadable="isFieldReadable"
        @saveCustomerAdditionalDetail="saveCustomerAdditionalDetail"
        :customerDetailDialog.sync="customerDetailDialog"
      />
      <EmployeeDialog
        @onEmployeeChange="onEmployeeChange"
        :dialog.sync="employeeDialog"
      />
      <SupplierDialog
        @onSupplierChange="onSupplierChange"
        :dialog.sync="supplierDialog"
      />
      <BankDialog @onBankChange="onBankChange" :dialog.sync="bankDialog" />
      <BookkeepingConfirmationDialog
        :dialog.sync="bookkeepingConfirmationDialog"
        :bookkeepingConfirmation="bookkeepingConfirmation"
      />
      <SupplierReceiptPayment
        :supplierReceiptInvoicesDialog.sync="supplierReceiptInvoicesDialog"
        :supplierReceiptId="supplierReceiptId"
        :supplierReceiptInvoicesModel.sync="supplierReceiptInvoicesModel"
        :supplierId="supplier_id"
        :grandTotal="model.grand_total"
        :paidTotal.sync="paidTotal"
        :supplierReceiptInvoicesType.sync="supplierReceiptInvoicesType"
        :updatedButton="type"
        :updatedDocuments="updatedDocuments"
        :accessRight="accessRight"
      />
      <RemarksDialog
        :dialog.sync="remarksDialog"
        :status="originModel.status"
        :docId="supplierReceiptId"
        :docType="24"
      />
      <confirmation-dialog
        :dialog.sync="confirmationDialogStatus"
        :delete_item.sync="delete_item"
        @deleteItem="deleteItem"
      />
      <AttachedDocumentsDialog
        :docId="supplierReceiptId"
        docType="SupplierReceipt"
        :dialog.sync="attachedDocumentsDialog"
      />
      <SupplierReceiptBookkeepingDialog
        v-if="supplierReceiptBookkeepingDialog"
        :dialog.sync="supplierReceiptBookkeepingDialog"
        :supplier="selectedSupplier"
      />
      <CancelNotDialog :dialog.sync="cancelNotDialog" type="supplierPayment" />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { getAuxiliaryZtableValueByName, changedFormData } from "@/utils";
import FormSkeleton from "@/components/skeleton/FormSkeleton";
import Alert from "@/components/Alert";
import SupplierDialog from "@/components/moduleTableDialog/Dialog/SupplierDialog";
import BankDialog from "@/components/moduleTableDialog/Dialog/BankDialog";
import SupplierReceiptPayment from "./Dialog/SupplierReceiptPayment";
import ActivityLogTableDialog from "@/components/activityLog/Dialogs/ActivityLogTableDialog.vue";
import BookkeepingRecordsDialog from "@/components/bookkeepingRecords/Dialogs/BookkeepingRecordsDialog.vue";
import CustomerDetail from "@/components/moduleTableDialog/Dialog/CustomerDetail";
import EmployeeDialog from "@/components/moduleTableDialog/Dialog/EmployeeDialog";
import HtmlToPdf from "@/components/generatePDF/HtmlToPdf";
import {
  formatDateDDMMYYYY,
  parseDateYYYYMMDD,
  todayDate,
} from "../../../utils";
import RemarksDialog from "@/components/RemarksDialog";
import BookkeepingConfirmationDialog from "@/components/BookkeepingConfirmationDialog";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import AttachedDocumentsDialog from "@/components/AttachedDocumentsDialog";
import SupplierReceiptBookkeepingDialog from "@/components/SupplierReceiptBookkeepingDialog";
import CancelNotDialog from "@/components/CancelNotDialog";
import NextAndPreviousCommon from "@/components/NextAndPreviousCommon";
import CommonCalendar from "@/components/CommonCalendar";
import { validationMixin } from "@/mixins/validationMixin";
import { intersectionMixin } from "@/mixins/intersectionMixin";
import { searchMixin } from "@/mixins/searchMixin";

export default {
  name: "SupplierPaymentAction",
  components: {
    FormSkeleton,
    Alert,
    CustomerDetail,
    ActivityLogTableDialog,
    BookkeepingRecordsDialog,
    EmployeeDialog,
    SupplierDialog,
    BookkeepingConfirmationDialog,
    SupplierReceiptPayment,
    BankDialog,
    HtmlToPdf,
    RemarksDialog,
    ConfirmationDialog,
    AttachedDocumentsDialog,
    SupplierReceiptBookkeepingDialog,
    CancelNotDialog,
    NextAndPreviousCommon,
    CommonCalendar,
  },
  mixins: [validationMixin, intersectionMixin, searchMixin],
  data() {
    return {
      message: "",
      customerDetailDialog: false,
      bookkeepingConfirmationDialog: false,
      bookkeepingRecordsDialog: false,
      alert: false,
      loading: false,
      valid: false,
      confirmationDialog: false,
      supplierAdditionalDetail: {},
      model: {
        status: 0,
        total: 0.0,
        discount: 0.0,
        after_discount: 0.0,
        vat: 0.0,
        grand_total: 0.0,
        receipt_date: "",
      },
      formattedReceiptDate: "",
      originModel: {},
      selectedSupplier: null,
      supplierDialog: false,
      selectedEmployee: null,
      employeeDialog: false,
      actDialog: false,
      startDateMenu: false,
      paymentItems: [
        {
          bank: null,
          branch: null,
          account_no: null,
          amount: null,
          supplier_payment_date: new Date().toISOString().substring(0, 10),
        },
      ],
      processedPaymentItems: [
        { formattedPaymentDate: "", supplier_payment_date: "" },
      ],
      paymentGroupValue: [],
      supplier_id: null,
      supplierPage: 1,
      supplierLimit: 10,
      employeePage: 1,
      employeeLimit: 10,
      dialog: false,
      selectedStatus: 0,
      supplierReceiptInvoicesModel: null,
      supplierReceiptInvoicesDialog: false,
      paidTotal: null,
      supplierReceiptInvoicesType: null,
      updatedDocuments: null,
      bankDialog: false,
      bankDialogIndex: null,
      nextId: null,
      prevId: null,
      remarksDialog: false,
      paymentTypeArray: ["Cheque", "המחאה"],
      cashPaymentArray: ["Cash", "מזומן"],
      confirmationDialogStatus: false,
      delete_item: null,
      type: null,
      attachedDocumentsDialog: false,
      saveOnlyCategoryStatus: false,
      isProgressCircular: false,
      supplierReceiptBookkeepingDialog: false,
      cancelNotDialog: false,
    };
  },
  computed: {
    statusList() {
      return [
        { name: this.$t("receipt.field.open"), id: 0 },
        { name: this.$t("receipt.field.finished"), id: 1 },
        { name: this.$t("receipt.field.canceled"), id: 2 },
      ];
    },
    isSupplierReceiptId() {
      return this.supplierReceiptId ? true : false;
    },
    receiptYearValid() {
      return [(v) => !!v || this.$t("formRules.receiptYearRequired")];
    },
    paymentDateValid() {
      return [
        (v) => !!v || this.$t("formRules.paymentDateRequired"),
        (v) => !/^\s*$/.test(v) || this.$t("formRules.paymentDateRequired"),
      ];
    },
    paymentTypeValid() {
      return [
        (v) => !!v || this.$t("formRules.paymentTypeRequired"),
        (v) => !/^\s*$/.test(v) || this.$t("formRules.paymentTypeRequired"),
      ];
    },
    chequeNumberValid() {
      return [
        (v) => !!v || this.$t("formRules.chequeNumberRequired"),
        (v) =>
          !v ||
          (v && v.length <= 20) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("receipt.chequeNumber"),
            type: this.$t("common.less"),
            size: 20,
          }),
        (v) =>
          !v ||
          /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/.test(v) ||
          this.$t("formRules.spaceValidValue"),
      ];
    },
    bankNoValid() {
      return [
        (v) => !!v || this.$t("formRules.bankNoRequired"),
        (v) =>
          !v ||
          (v && v.length <= 20) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("receipt.bankNo"),
            type: this.$t("common.less"),
            size: 20,
          }),
      ];
    },
    branchValid() {
      return [
        (v) => !!v || this.$t("formRules.branchRequired"),
        (v) =>
          !v ||
          (v && v.length <= 20) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("receipt.branch"),
            type: this.$t("common.less"),
            size: 20,
          }),
      ];
    },
    accountNoValid() {
      return [
        (v) => !!v || this.$t("formRules.accountNoRequired"),
        (v) =>
          !v ||
          (v && v.length <= 25) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("receipt.accountNo"),
            type: this.$t("common.less"),
            size: 25,
          }),
      ];
    },
    discountValid() {
      return [
        (v) =>
          !v ||
          /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/.test(v) ||
          this.$t("formRules.spaceValidValue"),
        (v) =>
          !v ||
          (v && String(v).length <= 12) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("quotation.field.discount"),
            type: this.$t("common.less"),
            size: 12,
          }),
      ];
    },
    amountValid() {
      return [
        (v) => !!v || this.$t("formRules.amountRequired"),
        (v) => !/^\s*$/.test(v) || this.$t("formRules.amountRequired"),
        (v) =>
          !v ||
          /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/.test(v) ||
          this.$t("formRules.spaceValidValue"),
        (v) =>
          !v ||
          (v && String(v).length <= 12) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("receipt.amount"),
            type: this.$t("common.less"),
            size: 12,
          }),
      ];
    },
    supplierReceiptId() {
      return this.$route.params.supplier_receipt_id;
    },
    ...mapGetters({
      supplierPaymentById: "supplierPayment/supplierPaymentById",
      locale: "locale",
      profileById: "profile/profileById",
      employeeById: "employee/employeeById",
      supplierList: "supplier/supplierList",
      remarksList: "remarks/remarksList",
      employeeList: "employee/employeeList",
      supplierPaymentQuery: "supplierPayment/supplierPaymentQuery",
      accessRight: "accessRight",
      user: "user",
      moreThanOneUserLogout: "moreThanOneUserLogout",
      bookkeepingConfirmation: "bookkeepingConfirmation",
    }),
    isFieldReadable() {
      return this.model.status == 0 ? false : true;
    },
    minDate() {
      if (this.supplierReceiptId) {
        const date = parseDateYYYYMMDD(this.model.receipt_date)?.split("-")[0];
        return `${date}-01-01`;
      } else {
        return null;
      }
    },
    maxDate() {
      if (this.supplierReceiptId) {
        const date = parseDateYYYYMMDD(this.model.receipt_date)?.split("-")[0];
        return `${date}-12-31`;
      } else {
        return null;
      }
    },
  },
  watch: {
    paymentItems: {
      handler(val) {
        this.processedPaymentItems = val.map((e) => ({
          ...e,
          formattedPaymentDate: this.profileById.date_format
            ? formatDateDDMMYYYY(e.supplier_payment_date)
            : e.supplier_payment_date,
        }));
      },
      deep: true,
    },
    "model.receipt_date"(val) {
      this.formattedReceiptDate = this.profileById.date_format
        ? val
        : formatDateDDMMYYYY(val);
      this.model.supplier_receipt_year = parseDateYYYYMMDD(val).split("-")[0];
    },
    supplierLimit(newVal, oldVal) {
      if (newVal !== oldVal)
        this.$store.dispatch("supplier/GetSupplier", {
          page: 1,
          limit: this.supplierLimit,
          order_by: "name_1|asc",
        });
    },
    employeeLimit(newVal, oldVal) {
      if (newVal !== oldVal)
        this.$store.dispatch("employee/GetEmployee", {
          page: 1,
          limit: this.employeeLimit,
          order_by: "name|asc",
        });
    },
    bookkeepingConfirmation: {
      handler: function (val) {
        if (val) {
          this.bookkeepingConfirmationDialog = true;
        }
      },
      deep: true,
    },
    supplierPaymentById() {
      if (this.$route.params.supplier_receipt_id) {
        if (Object.keys(this.supplierPaymentById).length > 0) {
          this.model = Object.assign({}, this.model, this.supplierPaymentById);
          if (this.model.receipt_date) {
            this.model.receipt_date = new Date(this.model.receipt_date)
              .toISOString()
              .substring(0, 10);
          }
          this.selectedStatus = this.model.status;
          this.originModel = Object.assign({}, this.originModel, this.model);
        }
      } else if (this.$route.params.copiedModel) {
        this.model = Object.assign({}, this.model, this.supplierPaymentById);
        delete this.model.created_at;
        delete this.model.id;
        delete this.model.is_printed;
        delete this.model.updated_at;
        delete this.model.accounting_update;
        this.model.supplier_receipt_year = new Date().getFullYear();
        this.model.receipt_date = new Date().toISOString().substring(0, 10);
      }
    },
    supplierReceiptInvoicesModel() {
      this.model = Object.assign(
        {},
        {
          ...this.model,
          invoiceData: this.supplierReceiptInvoicesModel?.invoiceData,
        }
      );
    },
    profileById() {
      this.processedPaymentItems = this.paymentItems.map((e) => ({
        ...e,
        formattedPaymentDate: this.profileById.date_format
          ? formatDateDDMMYYYY(e.supplier_payment_date)
          : e.supplier_payment_date,
      }));
      this.formattedReceiptDate = this.profileById.date_format
        ? this.model.receipt_date
        : formatDateDDMMYYYY(this.model.receipt_date);
    },
    async moreThanOneUserLogout() {
      if (this.moreThanOneUserLogout && this.model.id) {
        await this.$store.dispatch("PostDocumentOpenAction", {
          table: "SupplierReceipt",
          id: this.model.id,
          is_locked: false,
        });
        this.$store.dispatch("LogOut");
        this.$router.push({ name: "login" });
      }
    },
  },
  async created() {
    window.addEventListener("beforeunload", this.handler);
  },
  async mounted() {
    this.onMounteUpdated();
    if (this.supplierReceiptId) {
      this.$store.dispatch("remarks/GetRemarks", {
        where: "document_type|" + 24,
        where_and: "document_id|" + this.supplierReceiptId,
      });
    }
    this.selectedEmployee = this.user.name;
  },
  beforeDestroy() {
    if (
      (this.model.id &&
        !this.moreThanOneUserLogout &&
        this.model.is_locked &&
        this.model.user_id == this.user.id) ||
      (this.model.id && !this.model.user_id && !this.moreThanOneUserLogout)
    ) {
      this.$store.dispatch("PostDocumentOpenAction", {
        table: "SupplierReceipt",
        id: this.model.id,
        is_locked: false,
      });
    }
    this.$refs.form ? this.$refs.form.reset() : "";
    this.model = {};
    this.supplier_id = null;
    this.dialog = false;
    this.$store.commit("supplier/SET_SUPPLIER_BY_ID", {});
    this.$store.commit("supplierPayment/SET_SUPPLIER_PAYMENT_BY_ID", {});
    this.$store.commit("supplier/SET_SUPPLIER", []);
    this.$store.commit("SET_USER_LOGOUT", false);
    window.removeEventListener("beforeunload", this.handler);
  },
  methods: {
    todayDate: todayDate,
    copySupplierReceipt() {
      let copyModel = this.$route.params.supplier_receipt_id;
      this.$router.push({
        name: "addSupplierPayment",
        params: {
          copiedModel: copyModel,
        },
      });
    },
    async handler(event) {
      if (
        (this.model.id &&
          !this.moreThanOneUserLogout &&
          this.model.is_locked &&
          this.model.user_id == this.user.id) ||
        (this.model.id && !this.model.user_id && !this.moreThanOneUserLogout)
      ) {
        await this.$store.dispatch("PostDocumentOpenAction", {
          table: "SupplierReceipt",
          id: this.model.id,
          is_locked: false,
        });
      }
      event.preventDefault();
    },
    async onMounteUpdated() {
      if (
        this.$route.params.supplier_receipt_id ||
        this.$route.params.copiedModel
      ) {
        if (this.$route.params.supplier_receipt_id) {
          this.documentNextAndPrevious();
          await this.$store
            .dispatch(
              "supplierPayment/GetSupplierPaymentById",
              this.$route.params.supplier_receipt_id
            )
            .then((res) => {
              if (res.id) {
                document.title = document.title + "   " + res.id;
              }
            });
          if (!this.model.is_locked && this.$route.params.supplier_receipt_id) {
            await this.$store.dispatch("PostDocumentOpenAction", {
              table: "SupplierReceipt",
              id: this.$route.params.supplier_receipt_id,
              is_locked: true,
            });
          }
        } else if (this.$route.params.copiedModel) {
          // Copy invoice
          await this.$store.dispatch(
            "supplierPayment/GetSupplierPaymentById",
            this.$route.params.copiedModel
          );
          this.model.status = 0;
        }
        this.supplier_id = this.model?.supplier_id;
        this.selectedSupplier = {
          id: this.model?.supplier_id,
          name_1: this.model.supplier?.name_1,
          book_kiping_no: this.model.supplier?.book_kiping_no,
        };
        if (
          this.selectedSupplier &&
          !this.selectedSupplier?.book_kiping_no &&
          !this.supplierReceiptId
        ) {
          this.supplierReceiptBookkeepingDialog = true;
        }
        if (this.model.employee_id) {
          this.selectedEmployee = {
            id: this.model?.employee?.id,
            name: this.model?.employee?.name,
          };
          this.model.employee_id = this.model?.employee_id;
        }
        this.model.with_holding_tax = this.numberWithCommas(
          this.model.with_holding_tax,
          "noFloat"
        );
        // payment detail
        this.paymentItems = [];
        this.model.supplierReceiptPayment.forEach((element) => {
          delete element["created_at"];
          delete element["updated_at"];
          if (this.$route.params.copiedModel) {
            delete element["id"];
            delete element["supplier_receipt_id"];
          }
          let amount = this.numberWithCommas(element["amount"], "noFloat");
          this.paymentItems.push(
            Object.assign({}, { ...element, amount: amount })
          );
        });
        // updated documents detail
        if (this.model.supplierReceiptInvoices.length > 0) {
          this.updatedDocuments = this.model.supplierReceiptInvoices;
        }
      } else {
        if (this.$route.query.supplierId) {
          let supplier = await this.$store.dispatch(
            "supplier/GetSupplierById",
            this.$route.query.supplierId
          );
          let supplierModel = {};
          this.selectedSupplier = {
            id: supplier.id,
            name_1: supplier.name_1,
            book_kiping_no: supplier?.book_kiping_no,
          };
          if (
            this.selectedSupplier &&
            !this.selectedSupplier?.book_kiping_no &&
            !this.supplierReceiptId
          ) {
            this.supplierReceiptBookkeepingDialog = true;
          }
          if (this.$route.query.employeeId) {
            await this.$store.dispatch(
              "employee/GetEmployeeById",
              this.$route.query.employeeId
            );
            this.selectedEmployee = {
              id: this.employeeById.id,
              name: this.employeeById.name,
            };
            this.model.employee_id = this.employeeById.id;
          }
          this.model.supplier_id = supplier.id;
          this.supplier_id = supplier.id;
          let invoiceData = this.$route.query.invoiceData;
          this.model.supplier_name = invoiceData.supplier_name;
          supplierModel.authorized_dealer_no = invoiceData.authorized_dealer_no;
          supplierModel.email = invoiceData.email;
          supplierModel.address = invoiceData.address;
          supplierModel.town = invoiceData.town;
          supplierModel.country = invoiceData.country;
          supplierModel.phone_1 = invoiceData.phone_1;
          supplierModel.phone_2 = invoiceData.phone_2;
          supplierModel.cellular = invoiceData.cellular;
          supplierModel.fax = invoiceData.fax;
          this.model = Object.assign({}, this.model, supplierModel);
          this.model.status = 0;
          this.customerOrderNoRequired = supplier?.customer_order_no_compulsory;
          if (this.model.status === 0) {
            this.$refs.form.validate();
          }
        }
        this.model.supplier_receipt_year = new Date().getFullYear();
        this.model.receipt_date = new Date().toISOString().substring(0, 10);
      }
      this.$store.dispatch("profile/GetCompanyById");
      this.saveOnlyCategoryStatus = false;
      let tableNameValue = await getAuxiliaryZtableValueByName(8);
      if (Object.keys(tableNameValue).length > 0) {
        this.paymentGroupValue = tableNameValue.ztables.sort((a, b) =>
          a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
            ? 1
            : -1
        );
      }
    },
    async paymentTypeChange(item, index) {
      if (this.paymentTypeArray.includes(item.payment_terms_id.description)) {
        await this.$refs["bankRef" + index][0].onFocus();
        await this.$refs["branchRef" + index][0].onFocus();
        await this.$refs["accountNoRef" + index][0].onFocus();
        await this.$refs["chequeRef" + index][0].onFocus();
        await this.$refs["chequeRef" + index][0].onBlur();
      } else if (
        this.cashPaymentArray.includes(item.payment_terms_id.description)
      ) {
        this.$refs["amount" + index][0].onFocus();
      }
    },
    callBackFunction() {
      this.$router.push({
        name: "SupplierPayment",
        query: {
          ...this.supplierPaymentQuery,
        },
      });
    },
    async documentNextAndPrevious(supplierId = null) {
      if (this.supplierReceiptId) {
        let docPagination = await this.$store.dispatch(
          "GetDocumentPagination",
          {
            type: "supplier_receipts",
            customer_id: supplierId,
            document_id: Number(this.supplierReceiptId),
          }
        );
        this.nextId = docPagination.nextId ? docPagination.nextId : null;
        this.prevId = docPagination.prevId ? docPagination.prevId : null;
      }
    },
    async documentNextAndPreviousBlur(supplierId = null) {
      let self = this;
      setTimeout(async () => {
        if (self.supplierReceiptId) {
          let docPagination = await self.$store.dispatch(
            "GetDocumentPagination",
            {
              type: "supplier_receipts",
              customer_id: supplierId,
              document_id: Number(self.supplierReceiptId),
            }
          );
          self.nextId = docPagination.nextId ? docPagination.nextId : null;
          self.prevId = docPagination.prevId ? docPagination.prevId : null;
        }
      }, 1000);
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.supplierLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    async infiniteScrollOnEmployee([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.employeeLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    redirectOnNextAndPrevious(id) {
      if (id) {
        this.$router.push({
          path: "/supplierPayment/SupplierPaymentAction/" + id,
        });
      } else {
        return false;
      }
    },
    redirectOnEditSupplierPage() {
      if (this.selectedSupplier) {
        this.$router.push({
          path: "/supplier/SupplierAction/" + this.selectedSupplier.id,
        });
      } else {
        return false;
      }
    },
    redirectOnEditEmployeePage() {
      if (this.selectedEmployee && this.selectedEmployee.id) {
        this.$router.push({
          path: "/employee/EmployeeAction/" + this.selectedEmployee.id,
        });
      } else {
        return false;
      }
    },
    numberFromCommas(x) {
      return x ? x.toString().replace(/,/g, "") : null;
    },
    numberWithCommas(x, type = "float") {
      return x
        ? type === "float"
          ? parseFloat(x)
              .toFixed(2)
              .toString()
              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
          : x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
        : type === "float"
        ? (0).toFixed(2)
        : x;
    },
    onEmployeeChange(item) {
      if (item && typeof item === "object" && Object.keys(item).length > 0) {
        this.selectedEmployee = { id: item.id, name: item?.name };
        this.model.employee_id = item.id;
      } else {
        this.selectedEmployee = null;
        this.model.employee_id = null;
      }
    },
    openSupplierAdditionalDetailModel() {
      this.supplierAdditionalDetail.authorized_dealer_no =
        this.model.authorized_dealer_no;
      this.supplierAdditionalDetail.email = this.model.email;
      this.supplierAdditionalDetail.country = this.model.country;
      this.supplierAdditionalDetail.phone_1 = this.model.phone_1;
      this.supplierAdditionalDetail.phone_2 = this.model.phone_2;
      this.supplierAdditionalDetail.cellular = this.model.cellular;
      this.supplierAdditionalDetail.fax = this.model.fax;
      this.customerDetailDialog = true;
    },
    async saveCustomerAdditionalDetail() {
      this.model.authorized_dealer_no =
        this.supplierAdditionalDetail.authorized_dealer_no;
      this.model.email = this.supplierAdditionalDetail.email;
      this.model.country = this.supplierAdditionalDetail.country;
      this.model.phone_1 = this.supplierAdditionalDetail.phone_1;
      this.model.phone_2 = this.supplierAdditionalDetail.phone_2;
      this.model.cellular = this.supplierAdditionalDetail.cellular;
      this.model.fax = this.supplierAdditionalDetail.fax;
      if (this.$route.params.supplier_receipt_id) {
        this.isProgressCircular = true;
        await this.$store.dispatch("supplierPayment/EditSupplierPayment", {
          supplier_receipt_id: this.$route.params.supplier_receipt_id,
          supplierPayment: { ...this.supplierAdditionalDetail },
        });
        this.isProgressCircular = false;
      }
    },
    lessThanValidation(fieldValue, typeValue, sizeValue, v) {
      return (
        !v ||
        (v && String(v).length <= sizeValue) ||
        this.$t("formRules.lengthValidMessage", {
          fieldName: fieldValue,
          type: typeValue,
          size: sizeValue,
        })
      );
    },
    openBankDialog(index) {
      this.bankDialog = true;
      this.bankDialogIndex = index;
    },
    async onBankChange(bank) {
      if (bank && this.bankDialogIndex >= 0) {
        this.paymentItems[this.bankDialogIndex].bank = bank.name;
        this.paymentItems[this.bankDialogIndex].branch = bank.branch_name;
        this.paymentItems[this.bankDialogIndex].account_no = bank.account_no;
      }
    },
    async onSupplierChange(supplier) {
      if (
        supplier &&
        typeof supplier === "object" &&
        Object.keys(supplier).length > 0
      ) {
        let supplierModel = {};
        this.selectedSupplier = {
          id: supplier.id,
          name_1: supplier?.name_1,
          book_kiping_no: supplier?.book_kiping_no,
        };
        this.model.supplier_id = supplier.id;
        this.supplier_id = supplier.id;
        if (this.profileById.occasional_customer_no !== String(supplier.id)) {
          this.model.supplier_name = supplier?.name_1;
          if (supplier.employee_id) {
            supplier.employee_id
              ? await this.$store.dispatch(
                  "employee/GetEmployeeById",
                  supplier.employee_id
                )
              : "";
            this.selectedEmployee = {
              id: this.employeeById.id,
              name: this.employeeById.name,
            };
            this.model.employee_id = supplier.employee_id;
          } else {
            this.selectedEmployee = this.user.name;
          }
          supplierModel.authorized_dealer_no = supplier.authorized_dealer_no;
          supplierModel.email = supplier.email;
          supplierModel.address = supplier.address_1;
          supplierModel.town = supplier.town_1;
          supplierModel.country = supplier.country;
          supplierModel.phone_1 = supplier.phone_1;
          supplierModel.phone_2 = supplier.phone_2;
          supplierModel.cellular = supplier.cellular_1;
          supplierModel.fax = supplier.fax;
        } else {
          this.model.supplier_name = "";
          supplierModel.authorized_dealer_no = "";
          supplierModel.email = "";
          supplierModel.address = "";
          supplierModel.town = "";
          supplierModel.country = "";
          supplierModel.phone_1 = "";
          supplierModel.phone_2 = "";
          supplierModel.cellular = "";
          supplierModel.fax = "";
        }
        if (this.selectedSupplier && !this.selectedSupplier?.book_kiping_no) {
          this.supplierReceiptBookkeepingDialog = true;
        }
        this.model = Object.assign({}, this.model, supplierModel);
      }
    },
    itemPriceCalculation(index) {
      let item = this.paymentItems[index];
      let checkUnitPrice = item.amount;
      if (checkUnitPrice && this.$refs["amount" + index]) {
        const pos =
          this.$refs["amount" + index][0].$el.querySelector("input")
            .selectionStart + 1;
        if (
          checkUnitPrice.length !=
          this.numberWithCommas(this.numberFromCommas(item.amount), "noFloat")
            .length
        ) {
          this.$nextTick(() => {
            this.$refs["amount" + index][0]?.$el
              ? (this.$refs["amount" + index][0].$el.querySelector(
                  "input"
                ).selectionEnd = pos)
              : "";
          });
        } else {
          this.$nextTick(() => {
            this.$refs["amount" + index][0]?.$el
              ? (this.$refs["amount" + index][0].$el.querySelector(
                  "input"
                ).selectionEnd = pos - 1)
              : "";
          });
        }
      }
      this.paymentItems[index].amount = this.numberWithCommas(
        this.numberFromCommas(item.amount),
        "noFloat"
      );
      this.$nextTick(() => {
        if (this.$refs.form && this.$refs["amount" + index][0].validate()) {
          item.amount = this.numberFromCommas(item.amount);
        }

        this.paymentItems[index].amount = this.numberWithCommas(
          this.numberFromCommas(item.amount),
          "noFloat"
        );
        this.paymentItems[index] = item;
        this.receiptPriceCalculation();
      });
    },
    receiptPriceCalculation() {
      let self = this;
      this.model.total =
        this.paymentItems.length > 0
          ? this.paymentItems
              .reduce(function (amount, item) {
                return parseFloat(self.numberFromCommas(item.amount))
                  ? parseFloat(amount) +
                      parseFloat(self.numberFromCommas(item.amount))
                  : amount + 0;
              }, 0)
              .toFixed(2)
          : 0;

      let checkWithHoldingTax = this.model.with_holding_tax;
      if (checkWithHoldingTax && this.$refs["tax"]) {
        const pos =
          this.$refs["tax"].$el.querySelector("input").selectionStart + 1;
        if (
          checkWithHoldingTax.length !=
          this.numberWithCommas(
            this.numberFromCommas(this.model.with_holding_tax),
            "noFloat"
          ).length
        ) {
          this.$nextTick(() => {
            this.$refs["tax"]?.$el
              ? (this.$refs["tax"].$el.querySelector("input").selectionEnd =
                  pos)
              : "";
          });
        } else {
          this.$nextTick(() => {
            this.$refs["tax"]?.$el
              ? (this.$refs["tax"].$el.querySelector("input").selectionEnd =
                  pos - 1)
              : "";
          });
        }
      }

      this.model.with_holding_tax = this.numberWithCommas(
        this.numberFromCommas(this.model.with_holding_tax),
        "noFloat"
      );

      this.$nextTick(() => {
        if (this.$refs.form && this.$refs["tax"].validate()) {
          this.model.with_holding_tax = this.numberFromCommas(
            this.model.with_holding_tax
          );
        }

        this.model.with_holding_tax = this.numberWithCommas(
          this.numberFromCommas(this.model.with_holding_tax),
          "noFloat"
        );
      });

      // grand total amount calculation
      this.model.grand_total = parseFloat(
        this.numberFromCommas(this.model.with_holding_tax)
      )
        ? (
            parseFloat(this.numberFromCommas(this.model.with_holding_tax)) +
            parseFloat(this.model.total)
          ).toFixed(2)
        : parseFloat(this.model.total).toFixed(2);
    },
    addRow() {
      this.paymentItems.push({
        bank: null,
        branch: null,
        account_no: null,
        amount: null,
      });
      // scroll on new added row
      let newRow = document.querySelector(".newRow");
      let wrapper = document.querySelector(
        ".item-table div.v-data-table__wrapper"
      );
      this.$vuetify.goTo(newRow, { container: wrapper });
    },
    async copyData(i) {
      delete this.paymentItems[i].id;
      delete this.paymentItems[i].supplier_receipt_id;
      delete this.paymentItems[i].created_at;
      delete this.paymentItems[i].updated_at;
      delete this.paymentItems[i].is_deposited;
      delete this.paymentItems[i].receipt_payment_year;
      delete this.paymentItems[i].line_number;
      let obj = Object.assign({}, this.paymentItems[i]);
      const previousPaymentDate = new Date(
        this.paymentItems[i].supplier_payment_date
      );

      // Update the payment item's properties
      if (obj.payment_terms_id.description === "Cheque") {
        obj.cheque_no = String(Number(obj.cheque_no) + 1);
        if (
          previousPaymentDate.getDate() ===
          new Date(
            previousPaymentDate.getFullYear(),
            previousPaymentDate.getMonth() + 1,
            0
          ).getDate()
        ) {
          const newPaymentDate = new Date(0).setFullYear(
            previousPaymentDate.getFullYear(),
            previousPaymentDate.getMonth() + 1,
            new Date(
              previousPaymentDate.getFullYear(),
              previousPaymentDate.getMonth() + 2,
              0
            ).getDate()
          );
          obj.supplier_payment_date = new Date(newPaymentDate)
            .toISOString()
            .split("T")[0];
        } else {
          // If previousPaymentDate is not the last day of the month
          const newPaymentDate = new Date(
            previousPaymentDate.getFullYear(),
            previousPaymentDate.getMonth() + 1,
            previousPaymentDate.getDate() + 1
          );
          obj.supplier_payment_date = newPaymentDate
            .toISOString()
            .split("T")[0];
        }
      }
      this.paymentItems.push(obj);
    },
    deleteRow(index) {
      this.paymentItems.splice(index, 1);
      this.receiptPriceCalculation();
    },
    getInvoices(type) {
      if (!type && this.$refs.form.validate()) {
        this.supplierReceiptInvoicesType = type;
        this.supplierReceiptInvoicesDialog = true;
      } else if (type) {
        this.type = type;
        this.supplierReceiptInvoicesType = type;
        this.supplierReceiptInvoicesDialog = true;
      }
    },
    async saveSupplierReceipt() {
      if (
        this.$refs.form.validate() ||
        (this.$refs.form.validate() &&
          this.isFieldReadable &&
          this.saveOnlyCategoryStatus)
      ) {
        this.loading = true;
        let data;
        let paymentItems;
        paymentItems = this.paymentItems.filter((item) => {
          delete item["year"];
          delete item["startDateMenu"];
          if (
            item.payment_terms_id &&
            Object.keys(item.payment_terms_id).length > 0
          ) {
            item.payment_terms_id = item.payment_terms_id.id;
          }
          item.amount =
            this.numberFromCommas(item.amount) === ""
              ? null
              : this.numberFromCommas(item.amount);
          return item;
        });
        this.model.paymentData = paymentItems;
        delete this.model.discount;
        delete this.model.after_discount;
        delete this.model.vat;
        delete this.model.supplierReceiptPayment;
        delete this.model.supplierReceiptInvoices;
        if (
          this.selectedStatus &&
          Object.keys(this.selectedStatus).length > 0
        ) {
          this.model.status = this.selectedStatus.id;
        } else {
          this.model.status = this.selectedStatus;
        }
        if (this.model.paymentData.length > 0) {
          if (this.model.invoiceData && this.model.invoiceData.length > 0) {
            this.model.invoiceData.filter((item) => {
              item.amount_paid =
                this.numberFromCommas(item.amount_paid) === ""
                  ? null
                  : this.numberFromCommas(item.amount_paid);
              return item;
            });
          }

          this.model.with_holding_tax =
            this.numberFromCommas(this.model.with_holding_tax) === ""
              ? null
              : this.numberFromCommas(this.model.with_holding_tax);

          if (this.$route.params.supplier_receipt_id) {
            // save only update model value using changedFormData from utils
            this.model.status = 1;
            let saveData = changedFormData(this.model, this.originModel);
            saveData.receipt_date = parseDateYYYYMMDD(this.model.receipt_date);
            this.isProgressCircular = true;
            data = await this.$store
              .dispatch("supplierPayment/EditSupplierPayment", {
                supplier_receipt_id: this.$route.params.supplier_receipt_id,
                supplierPayment: saveData,
              })
              .catch((e) => {
                this.loading = false;
                console.log(e);
              });
            if (data) {
              this.$refs.form.reset();
              this.loading = false;
              this.supplierReceiptInvoicesType = null;
              this.supplierReceiptInvoicesModel = null;
              this.supplierReceiptInvoicesDialog = false;
              this.paidTotal = null;
              this.updatedDocuments = null;
              await this.onMounteUpdated();
            }
            this.isProgressCircular = false;
          } else {
            this.model.status = 1;
            data = await this.$store
              .dispatch("supplierPayment/SetSupplierPayment", this.model)
              .catch((e) => {
                this.loading = false;
                console.log(e);
              });
            if (data) {
              this.$refs.form.reset();
              this.loading = false;
              if (this.accessRight.includes("edit")) {
                this.model.status = 1;
                await this.$router
                  .push("/supplierPayment/editSupplierPayment/" + data.data.id)
                  .catch(() => {});
                this.$store.commit(
                  "SET_BOOKKEEPING_CONFIRMATION",
                  data.data.abc
                );
              } else {
                this.callBackFunction();
              }
            }
          }
        } else {
          this.message = "formRules.paymentItemAlert";
          this.alert = true;
          this.loading = false;
        }
      } else {
        this.message = "formRules.formAlert";
        this.alert = true;
      }
    },
    async deleteItem() {
      this.isProgressCircular = true;
      await this.$store
        .dispatch(
          "supplierPayment/DeleteSupplierPaymentById",
          this.$route.params.supplier_receipt_id
        )
        .then((res) => {
          if (res.data.type && res.data.type === "delete") {
            this.cancelNotDialog = true;
            this.isProgressCircular = false;
          } else {
            this.$router
              .push(
                "/supplierPayment/editSupplierPayment/" +
                  this.$route.params.supplier_receipt_id
              )
              .catch(() => {});
            this.model.status = 2;
            this.originModel.status = 2;
            this.selectedStatus = 2;
            this.isProgressCircular = false;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.date-input ::v-deep input {
  font-size: 12px;
}
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
}
</style>
